import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { styled } from 'linaria/react';
import React from 'react';
import UIMaxWidth from '../Layout/MaxWidth';

const Header = styled('h1')`
  font-size: 1.6875rem;
  font-weight: 600;
  margin: 1rem 0 1rem;
`;

const Content = styled('div')`
  padding-bottom: 2rem;

  p {
    margin-bottom: 1rem;
  }
`;

const MaxWidth = styled(UIMaxWidth)`
  max-width: 60rem;
`;

function ContentPage(props) {
  const { page } = props;

  return (
    <>
      <Header>
        <MaxWidth>
          {page ? page.name : <LoadingLine heightPx={27} widthRem={15} />}
        </MaxWidth>
      </Header>
      <MaxWidth>
        {page ? (
          <>
            <Content
              dangerouslySetInnerHTML={{
                __html: page.content
              }}
            />
          </>
        ) : (
          <LoadingLine
            heightPx={14}
            widthRem={30}
            randomizeWidthBy={10}
            count={10}
            style={{
              marginBottom: '12px'
            }}
          />
        )}
      </MaxWidth>
    </>
  );
}

export default ContentPage;
